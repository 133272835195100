import {
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BLink,
  BFormText,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardBody,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BInputGroupPrepend,
  BTable,
  BTab,
  BTabs,
  BFormRadio,
  BSidebar,
  BCardHeader,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInvalidFeedback,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormFile,
  BFormTextarea,
  BOverlay,
  BPagination,
  BPaginationNav,
  BFormDatepicker,
  BFormTimepicker,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BPopover,
  BCalendar,
  BFormSpinbutton,
  BInputGroupText,
  VBPopover,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InputMask from 'vue-input-mask'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import InitFileManager from '@/components/InitFileManager'

const GlobalMixin = {
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BLink,
    BFormText,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardBody,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BCard,
    BInputGroupPrepend,
    BTable,
    BTab,
    BTabs,
    BCardHeader,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BSidebar,
    BFormInvalidFeedback,
    BMedia,
    BMediaBody,
    BMediaAside,
    BFormFile,
    BFormTextarea,
    BOverlay,
    BPagination,
    BPaginationNav,
    BFormDatepicker,
    BFormTimepicker,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BPopover,
    BCalendar,
    BFormSpinbutton,
    BInputGroupText,
    vSelect,
    InputMask,
    InitFileManager,
  },
  data() {
    return {
      userData: null,
      statusOptions: [
        { label: this.$t('general.statuses.enabled'), value: 'enabled' },
        { label: this.$t('general.statuses.disabled'), value: 'disabled' },
      ],
      userReviewsOptions: [
        { label: this.$t('messages.types.admin.buyer'), value: 'buyer' },
        { label: this.$t('messages.types.admin.seller'), value: 'seller' },
      ],
      pageTypeOptions: [
        { label: this.$t('general.pagetype.default'), value: 'default' },
        { label: this.$t('general.pagetype.home'), value: 'home' },
      ],
      statusOptionsFull: [
        { label: this.$t('general.statuses.enabled'), value: 'enabled' },
        { label: this.$t('general.statuses.disabled'), value: 'disabled' },
        { label: this.$t('general.statuses.pending'), value: 'pending' },
        { label: this.$t('general.statuses.approved'), value: 'approved' },
        { label: this.$t('general.statuses.rejected'), value: 'rejected' },
      ],
      statusOptionsTransactions: [
        { label: this.$t('general.statuses.pending'), value: 'pending' },
        { label: this.$t('general.statuses.approved'), value: 'approved' },
        { label: this.$t('general.statuses.rejected'), value: 'rejected' },
      ],
      languageOptions: [
        { label: this.$t('general.languages.ukrainian'), value: 'ukrainian' },
        { label: this.$t('general.languages.english'), value: 'english' },
        { label: this.$t('general.languages.russian'), value: 'russian' },
      ],
      productTypeOptions: [
        { label: this.$t('general.producttype.channel'), value: 'channel' },
        { label: this.$t('general.producttype.group'), value: 'group' },
      ],
      statusProjectOptions: [
        {
          label: this.$t('general.projectStatus.draft'), value: 'draft', color: 'secondary', icon: '',
        },
        {
          label: this.$t('general.projectStatus.pending'), value: 'pending', color: 'secondary', icon: '',
        },
        {
          label: this.$t('general.projectStatus.work'), value: 'work', color: 'info', icon: '',
        },
        {
          label: this.$t('general.projectStatus.cancel'), value: 'cancel', color: 'warning', icon: '',
        },
        {
          label: this.$t('general.projectStatus.completed'), value: 'completed', color: 'success', icon: '',
        },
        {
          label: this.$t('general.projectStatus.archive'), value: 'archive', color: 'secondary', icon: '',
        },
      ],
      statusTaskOptions: [
        {
          label: this.$t('general.taskStatus.draft'), value: 'draft', color: 'secondary', icon: '',
        },
        {
          label: this.$t('general.taskStatus.pending'), value: 'pending', color: 'secondary', icon: '',
        },
        {
          label: this.$t('general.taskStatus.work'), value: 'work', color: 'info', icon: '',
        },
        // {
        //   label: this.$t('general.taskStatus.appeal'), value: 'appeal', color: 'danger', icon: '',
        // },
        {
          label: this.$t('general.taskStatus.completed'), value: 'completed', color: 'success', icon: '',
        },
        {
          label: this.$t('general.taskStatus.review'), value: 'review', color: 'secondary', icon: '',
        },
        {
          label: this.$t('general.taskStatus.revision'), value: 'revision', color: 'secondary', icon: '',
        },
        {
          label: this.$t('general.taskStatus.cancel'), value: 'cancel', color: 'warning', icon: '',
        },
        {
          label: this.$t('general.taskStatus.return'), value: 'return', color: 'warning', icon: '',
        },
        // {
        //   label: this.$t('general.taskStatus.archive'), value: 'archive', color: 'secondary', icon: '',
        // },
      ],
      koefOptions: {
        '15min': 1,
        '30min': 1,
        '1day': 1,
        '2days': 1.2,
        '3days': 1.4,
        eternal: 2,
        repost: 1,
      },
      priceTypeOptions: [
        { label: this.$t('general.price_types.15min'), value: '15min' },
        { label: this.$t('general.price_types.30min'), value: '30min' },
        { label: this.$t('general.price_types.1day'), value: '1day' },
        { label: this.$t('general.price_types.2days'), value: '2days' },
        { label: this.$t('general.price_types.3days'), value: '3days' },
        { label: this.$t('general.price_types.eternal'), value: 'eternal' },
        { label: this.$t('general.price_types.repost'), value: 'repost' },
      ],
      priceTypeFilterOptions: [
        { label: this.$t('catalog.price.types.15min'), value: '15min' },
        { label: this.$t('catalog.price.types.30min'), value: '30min' },
        { label: this.$t('catalog.price.types.1day'), value: '1day' },
        { label: this.$t('catalog.price.types.2days'), value: '2days' },
        { label: this.$t('catalog.price.types.3days'), value: '3days' },
        { label: this.$t('catalog.price.types.eternal'), value: 'eternal' },
        { label: this.$t('catalog.price.types.repost'), value: 'repost' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  mounted() {
    if (localStorage.getItem('userData')) {
      this.userData = JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    confirm(callback, options) {
      const data = {
        title: this.$t('notifications.warning'),
        text: this.$t('notifications.confirm.destroy.text'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('notifications.confirm.destroy.buttons.cancel'),
        confirmButtonText: this.$t('notifications.confirm.destroy.buttons.yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        ...options || {},
      }

      this.$swal(data).then(result => {
        if (result.isConfirmed) {
          callback(result)
        }
      })
    },
  },
}

export default GlobalMixin
